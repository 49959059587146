// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

global.$ = global.jQuery;
//global._ = require('underscore');

var Header = require('../_modules/header/header');
var Modal = require('../_modules/modal/modal');

$(function() {

  new Header();
  new Modal();
});
