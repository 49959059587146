'use strict';

// Constructor
var Modal = function() {
  var modal = $('.mow-modal');
  
  if (modal.length) {
    var modalTrigger = $('[data-modal]');

    modalTrigger.on('click', function() {
      var $this = $(this);

      if ($this.data('modal') === 'open') {
        modal.addClass('-open');
      } else {
        modal.removeClass('-open');
      }

    });

    var loginBoxSwitch = $('[data-target]');
    var loginBoxContent = $('[data-content]');

    loginBoxSwitch.on('click', function() {
      var $this = $(this);
      var action = $this.data('target');

      loginBoxContent.removeClass('-active');

      loginBoxContent.filter(function() {
        return $(this).data('content') === action;
      }).addClass('-active');      
    });
  }
};

module.exports = Modal;